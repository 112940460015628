.label {
    @apply text-gray-900 font-medium w-full;
    /*@apply dark:text-gray-200;*/
}

.hint {
    @apply text-gray-400 leading-4;
}

.requiredSign {
    @apply text-red-600 ml-2 inline-block font-bold;
}

.dFlex {
    @apply flex;
}
