

.wrapper {
    @apply flex border items-center border-gray-200 bg-white px-4 py-2 rounded-lg space-x-2 focus-within:ring-2 focus-within:ring-purple-600;
    /*@apply dark:bg-gray-900 dark:border-gray-700;*/
}

.disabledWrapper {
    @apply bg-gray-200;
    /*@apply dark:bg-gray-600;*/
}

.select {
    @apply appearance-none w-full flex-grow bg-transparent focus:outline-none;
    /*@apply dark:text-gray-200;*/
}

.loader {
    @apply text-purple-600 h-6 w-6;
}

.feedbackZone {
    @apply flex justify-between;
}

.feedbackError {
    @apply text-red-500 mt-1;
}

.feedbackCounter {
    @apply text-sm;
}

.requiredSign {
    @apply text-red-600 ml-2 inline-block font-bold;
}
