.btn {
  @apply disabled:bg-gray-200 cursor-pointer inline-flex justify-center items-center gap-2 transform active:scale-95 rounded-xl text-base leading-6 font-medium focus:outline-none sm:text-sm sm:leading-5 transition-all ease-in-out duration-150;
}

.btn * {
  @apply cursor-pointer;
}

.default {
  @apply btn;
  @apply border border-gray-300 bg-white text-gray-700 transition-transform hover:text-gray-500 focus:border-blue-300;
}

.primary {
  @apply btn;
  @apply border border-transparent bg-primary text-white focus:border-blue-700;
}

.dangerous {
  @apply btn;
  @apply border border-transparent bg-red-600 text-white hover:bg-red-500 focus:border-red-700;
}

.ghost {
  @apply btn;
  @apply bg-gray-800 backdrop-filter backdrop-blur-lg text-white bg-opacity-50;
}

.sm {
  @apply px-4 py-2;
}

.md {
  @apply px-4 py-3;
}

.lg {
  @apply px-4 py-4;
}