.overlay {
    @apply fixed z-30 inset-0 overflow-y-auto;
}

.overlayBox {
    @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
}

.overlayBackground {
    @apply fixed inset-0 transition-opacity;
}

.overlayBackgroundInner {
    @apply absolute inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-md backdrop-filter;
    /*@apply dark:bg-gray-900 dark:bg-opacity-50;*/
}

.transitionChild1Enter {
    @apply ease-out duration-300;
}
.transitionChild1EnterFrom {
    @apply opacity-0;
}
.transitionChild1EnterTo {
    @apply opacity-100;
}
.transitionChild1Leave {
    @apply ease-in duration-200;
}
.transitionChild1LeaveFrom {
    @apply opacity-100;
}
.transitionChild1LeaveTo {
    @apply opacity-0;
}

.trick {
    @apply hidden sm:inline-block sm:align-middle sm:h-screen;
}

.transitionChild2Base {
    @apply inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full;
}

.transitionChild2Enter {
    @apply transition-all ease-out duration-300;
}

.transitionChild2EnterFrom {
    @apply opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95;
}

.transitionChild2EnterTo {
    @apply opacity-100 translate-y-0 sm:scale-100;
}

.transitionChild2Leave {
    @apply ease-in duration-200;
}

.transitionChild2LeaveFrom {
    @apply opacity-100 translate-y-0 sm:scale-100;
}

.transitionChild2LeaveTo {
    @apply opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95;
}

.container {
    @apply bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4;
    /*@apply dark:border-gray-400 dark:bg-gray-800;*/
}

.containerFlex {
    @apply sm:items-start w-full flex;
}

.dangerBackgroundIcon {
    @apply mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10;
}

.dangerIcon {
    @apply h-6 w-6 text-red-600 ;
}

.content {
    @apply mt-3 w-full sm:mt-0 sm:text-left;
}

.dangerContent {
    @apply sm:ml-4;
}

.title {
    @apply text-lg mb-2 leading-6 font-medium text-gray-900;
    /*@apply dark:text-gray-200;*/
}

.buttons {
    @apply px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse;
    /*@apply dark:bg-gray-800;*/
}

.dangerConfirm {
    @apply w-full inline-flex justify-center rounded-lg active:scale-95 transform transition-all border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm;
}

.regularConfirm {
    @apply w-full inline-flex justify-center rounded-lg active:scale-95 transform transition-all border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm;
}

.cancel {
    @apply mt-3 w-full inline-flex justify-center rounded-lg active:scale-95 transform transition-all border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm;
}
