.wrapper {
  @apply flex space-x-2 items-center;
}

.wrapperDisabled {
  @apply text-gray-400 cursor-not-allowed;
}

.track {
  @apply w-14 h-8 flex items-center focus:outline-none bg-gray-300 rounded-full p-1 duration-300 cursor-pointer;
}

.trackOn {
  @apply bg-primary;
}

.trackDisabled {
  @apply bg-opacity-50 cursor-not-allowed;
}

.ball {
  @apply bg-white w-6 h-6 rounded-full shadow-md transition-transform transform duration-300;
}

.ballOn {
  @apply translate-x-6;
}