.mainWrapper {
  @apply flex flex-col;
}

.label {
  @apply text-gray-900 font-medium;
  /*@apply dark:text-gray-200;*/
}

.hint {
  @apply text-gray-400 leading-4;
}

.inner {
  @apply border rounded-lg flex;
}

.inner-default {
  @apply inner;
  @apply border-gray-200;
}

.inner-success {
  @apply inner;
  @apply border-green-200 bg-green-50 text-green-600;
}

.inner-error {
  @apply inner;
  @apply border-red-200 bg-red-50 text-red-500;
}


/*.inputWrapper {*/
/*    @apply flex items-center bg-white border border-gray-100 rounded-md focus-within:ring-2;*/
/*    !*@apply dark:border-gray-700;*!*/
/*}*/

.baseInputClasses {
  @apply text-gray-900 bg-transparent p-2 w-full flex-grow disabled:bg-gray-200;
  /*@apply dark:bg-gray-900 dark:text-gray-200 dark:placeholder-blue-400 dark:disabled:bg-gray-600;*/
  @apply outline-none;
}

.feedbackZone {
  @apply flex justify-between;
}

.feedbackError {
  @apply text-red-500 mt-1;
}

.feedbackCounter {
  @apply text-sm ;
}

.requiredSign {
  @apply text-red-600 ml-2 inline-block font-bold;
}

.dFlex {
  @apply flex;
}
