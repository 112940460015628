.baseDropdownItem {
    @apply block w-full focus:outline-none text-left text-sm cursor-pointer px-4 py-2;
}

.dropdown-item-default {
    @apply baseDropdownItem;
    @apply text-gray-800 hover:bg-gray-100;
}

.dropdown-item-dangerous {
    @apply baseDropdownItem;
    @apply text-red-500 hover:bg-red-100 hover:bg-opacity-50;
}
